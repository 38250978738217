.ekko-lightbox {}
.ekko-lightbox .modal-header,
.ekko-lightbox .modal-body,
.ekko-lightbox .modal-footer {
    padding: 10px;
}
.ekko-lightbox .modal-header .modal-title {
    font-size: 11pt;
    color: #ccc;
}

.ekko-lightbox-container .modal-loading {
    text-align: center;
    font-size: 20px;
    padding-top: 80px;
    padding-bottom: 80px;
}

/* ajax で埋め込むプロフィールに適用 */
@media (max-width:767px){
    .lb-profile .modal-dialog,
    .lb-mention .modal-dialog {
        min-width: 300px;
    }
}

.ekko-lightbox .square-box {
    position: relative;
}
.ekko-lightbox .square-box:before{
    content: "";
    display: block;
    padding-top: 100%; 	/* initial ratio of 1:1*/
}
.ekko-lightbox .square-content {
    position:  absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.ekko-lightbox .center-box {
    position:  absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
}
.ekko-lightbox .profile-icon-box {
    box-shadow: rgba(0,0,0,0.2) 0 4px 8px;
}
.ekko-lightbox .profile-account {
    margin: 8px 0;
}
.ekko-lightbox .profile-introduction {
    margin: 8px 0;
    padding: 8px 0 0;
    border-top: 1px solid #ddd;
    max-height: 350px;
    overflow-y: auto;
    /*white-space: pre-wrap;*/
}
