/*noinspection CssInvalidHtmlTagReference*/
blink {
    animation: blink-animation 0.5s linear infinite;
}
@keyframes blink-animation {
    0% { opacity: 0; }
    19.9% { opacity: 0; }
    20% { opacity: 1; }
    100% { opacity: 1; }
}
