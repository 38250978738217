html {
    height: 100%;
}
body {
    height: 100%;

    & .wrap {
        margin: 0 auto -140px;
        padding: 0 0 140px;
    }
}
body.use-affix {
    height: auto;
    min-height: 800px;
    background-color: #f5f5f5;

    & .wrap {
        min-height: 800px;
        background-color: #ffffff;
    }
}

.clearfix-tail-only::after {
    content: " ";
    display: table;
}

/* 「管理」のボタン */
.manager-shortcut {
    position: relative;
    height: 0;
    overflow: visible;

    & .btn {
        position: absolute;
        right: 4px;
        top: 4px;
        opacity: 0.5;
        z-index: 2;
    }
    & .btn:hover {
        opacity: 1.0;
    }
}


.account-index .nav .fa {
    vertical-align: middle;
}

.nav-x-scroll-wrap {
    margin-bottom: 15px;
}
@media (max-width: 479px) {
    .nav-x-scroll-wrap {
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
    }
    .nav-x-scroll-wrap .nav {
        white-space: nowrap;
        display: flex;
    }
}

.discussion-header {
    margin: -20px 0 20px;
    background-size: cover;
    background-position: center;

    & .discussion-header-inside {
        padding: 20px 10px 5px;
        min-height: 170px;
    }

    & .container {
        position: relative;
    }

    & .discussion-header-down-tone {
        background: linear-gradient(to bottom,  rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.1) 100%);
    }

    & .symbol-image {
        float: left;
        margin: 0 20px 15px 0;

        & .thumbnail {
            box-shadow: 0 4px 8px rgba(0,0,0,0.2);
            margin-bottom: 0;
            padding: 0;
            border: 5px solid #ffffff;
            border-radius: 10px;
            overflow: hidden;
        }
        & img {
            width: 128px;
            height: 128px;
        }
    }

    & .title-block {
        padding-left: 150px;
        min-height: 74px;
    }
    & .forum-title {
        padding: 0 0 10px;
        margin: 20px 0 10px;
        color: white;
        font-size: 36px;
        border-bottom: 1px solid white;
        text-shadow: 0 2px 8px rgba(0,0,0,0.3);
        word-break: break-all;

        & .label {
            font-size: 12px;
            vertical-align: middle;
        }
    }
    & .page-title {
        margin: 0 0 28px;
        color: white;
        font-size: 24px;
        text-shadow: 0 4px 8px rgba(0,0,0,0.3);
        word-break: break-all;
        /*width: 760px;*/
    }
    & .access-info {
        margin-top: -28px;
        margin-bottom: 28px;
    }

    & .activity-info {
        position: absolute;
        bottom: 0;
        right: 15px;

        & .comment-activity,
        & .access-activity,
        & .watch-activity {
            float: left;
            width: 100px;
            height: 40px;
            padding: 4px 10px;
            vertical-align: top;
            background: rgba(255,255,255,0.7);
            margin-right: 4px;
            border-radius: 4px;
        }
    }
}

@media (max-width: 1199px) {
    .discussion-header .page-title {
        /*width: 570px;*/
    }
}

@media (max-width: 991px) {
    .discussion-header {
        & .page-title {
            /*width: auto;*/
            margin-bottom: 0;
        }
        & .access-info {
            margin-top: 0;
            margin-bottom: 0;
        }
        & .activity-info {
            position: static;
            float: right;
            margin: 10px 0 0 0;
        }
    }
}

@media (max-width: 767px) {
    .discussion-header {
        & .discussion-header-inside {
            min-height: 106px;
        }
        & .symbol-image {
            width: 106px;
        }
        & .symbol-image img {
            width: 96px;
            height: 96px;
        }
        & .title-block {
            padding-left: 128px;
        }
        & .forum-title {
            margin-bottom: 5px;
            font-size: 30px;
        }
        & .page-title {
            font-size: 20px;
        }
    }
}
@media (max-width: 479px) {
    .discussion-header {
        & .symbol-image {
            float: none;
            margin: 0 auto;
        }
        & .title-block {
            padding-left: 0;
        }
        & .forum-title {
            margin-bottom: 0;
            font-size: 20px;
            text-align: center;
            border-bottom: none;
            padding-bottom: 0;
        }
        & .page-title {
            font-size: 16px;
            text-align: center;
        }
        & .access-info {
            text-align: center;
        }
        & .activity-info {
            float: none;
            margin: 10px -25px 0;
            padding: 0;
        }
        & .activity-info-inner {
            margin: 0 auto;
            width: 312px;
        }
    }
}

.discussion-header .activity-info {

    & .comment-activity .comment-activity-total,
    & .access-activity .access-activity-total,
    & .watch-activity .watch-activity-total {
        display: block;
        text-align: center;
    }

    & .comment-activity .comment-activity-total {
        & a {
            color: black;
        }
        & a:hover {
            color: #2183b4;
        }
    }

    & .comment-activity .comment-activity-total .comment-activity-total-value,
    & .access-activity .access-activity-total .pv-total,
    & .watch-activity .watch-activity-total .watch-activity-total-value {
        display: block;
        font-size: 14px;
        font-weight: bold;
        height: 20px;
    }
    & .comment-activity .comment-activity-total small,
    & .access-activity .access-activity-total small,
    & .watch-activity .watch-activity-total small {
        display: block;
        line-height: 1em;
        margin: -2px 0 2px;
        font-size: 10px;
    }
    & .access-activity .access-activity-total small {
        margin-top: -3px;
        font-size: 11px;
    }
    & .watch-activity .watch-toggle {
        white-space: nowrap;
        color: white;
        font-weight: bold;
    }

    //& .access-activity .access-activity-today .access-activity-label,
    //& .access-activity .access-activity-yesterday .access-activity-label {
    //}
    //& .access-activity .access-activity-today .pv-today,
    //& .access-activity .access-activity-yesterday .pv-yesterday {
    //}
}

.owner-info {
    margin: 18px 0 0;
    text-align: right;
    font-size: 90%;

    & .posted-user {
        margin-bottom: 4px;

        & .owner-icon-container {
            display: inline-block;
            vertical-align: middle;
        }
        & .owner-icon {
            margin: 0;
            width: 24px;
            height: 24px;
            border-radius: 2px;
            border: none;
            padding: 1px;
            outline: none;
        }
        & .owner-icon img {
            display: block;
        }
        & .owner-icon-container a {
            display: block;
        }
        & .owner-icon-container a:hover,
        & .owner-icon-container a:focus,
        & .owner-icon-container a:active {
            text-decoration: none;
        }
        & .owner-icon-container a:hover .owner-icon,
        & .owner-icon-container a:focus .owner-icon,
        & .owner-icon-container a:active .owner-icon {
            box-shadow: rgba(68,170,221,1) 0 0 0 1px;
        }
    }
}

/* dashboard */
.user-page-header {
    margin: 0 0 20px;

    & .user-icon {
        float: left;
        width: 60px;
        height: 60px;
        margin: 0 10px 0 0;
    }
    & .user-page-header-text {
        padding-left: 70px;
    }
    & .title {
        color: #317EAC;
        font-size: 26px;
        font-weight: bold;
        margin: 12px 0 4px;
    }
    & .user-page-header-desc {
        color: #888;
        font-size: 90%;
        margin: 4px 0;
    }
}

.dashboard-index .unread {
    background-color: lightyellow;
}

.dashboard-index,
.dashboard-reaction,
.watch-list-index {
    & .list-view-item .text {
        padding-left: 70px;
    }
}

.watch-list-index .list-view-item {
    & .forum {
        & .forum-title {
            font-size: 16px;
        }
        & .topic-title {
            font-size: 12px;
            color: #cccccc;
        }
    }
    & .topic {
        & .forum-title {
            font-size: 12px;
        }
        & .topic-title {
            font-size: 16px;
        }
    }
}

.dashboard-index, .dashboard-reaction {
    & .list-view-item {
        & .comment-number {
            font-size: 14px;
        }

        & .topic-header-body-container,
        & .comment-header-body-container,
        & .comment-header-body-deleted {
            margin-left: 74px;
            padding-left: 10px;
            border-left: 4px solid #ccc;
        }
    }
}

.dashboard-index .topic {
    & .header {
        font-size: 90%;
        margin-bottom: 2px;
        word-break: break-all;

        & .posted-user {
            margin-right: 0.5em;
        }
        & .topic-timestamp {
            display: inline-block;
            margin-right: 0.5em;
        }
        & .topic-timestamp a {
            color: #333;
        }
        & .topic-timestamp a:active,
        & .topic-timestamp a:focus,
        & .topic-timestamp a:hover {
            color: #44aadd;
        }
    }

    & .modification {
        color: #999999;
        font-size: 80%;
    }
}

.dashboard-abuse-report-index {
    & .reporting {
        padding: 8px;
        margin: 8px 10% 8px 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        background: #eee;
    }
    & .moderation {
        padding: 8px;
        margin: 8px 0 8px 10%;
        border: 1px solid #5cc3cc;
        border-radius: 4px;
        background: #acecff;
    }
}

.tools-container {
    margin: 8px 0;
    white-space: nowrap;

    & > .tools-container-content {
        float: right;
        @media (max-width: 767px) {
            float: none;
        }
    }
}

.block-link {
    position:relative;
}
.block-link > a.block-link-anchor {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    text-indent:-9999px;
}
.block-link:hover a {
    text-decoration: underline;
}

/** social **/
.social-share-buttons {
    min-height: 20px;
    margin: 0 0 15px;
    overflow: hidden;
}
.social-share-buttons > * {
    margin-left: 5px;
    margin-bottom: 5px;
    vertical-align: top;
}

/** misc */
.topic-detail .topic-operations-container,
.comment-detail .comment-operations-container {
    min-height: 40px;
}

/** Drawer v3 */
.drawer--right .drawer-hamburger {
    border-radius: 4px;
    border: none;
    background: #000;
    opacity: 0.2;
    width: 42px;
    height: 40px;
    top: auto;
    right: 20px;
    bottom: 300px;
    outline: none;
    padding: 15px;
    box-sizing: border-box;

    &:hover, &:active {
        opacity: 0.5;
    }

    @media (max-width: 767px) {
        right: 10px;
    }

    & .drawer-hamburger-icon {
        background-color: white;
        margin-top: 0;

        &:before {
            background-color: white;
            top: -6px;
        }
        &:after {
            background-color: white;
            top: 6px;
        }
    }
}

.drawer--right.drawer-open .drawer-hamburger {
    right: 275px;
    opacity: 1.0;
    background-color: transparent;
    top: 0;
    bottom: auto;

    & .drawer-hamburger-icon {
        background-color: transparent;
        &:before, &:after {
            top: 0;
            height: 5px;
        }
    }
}

.drawer--right .drawer-nav {
    right: -280px;
    width: 280px;
}

.drawer .drawer-nav {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 1040;
}
.drawer .drawer-hamburger {
    z-index: 1040;
}
.drawer .drawer-iscroll {
    padding-bottom: 40px;
}
.drawer .drawer-white {
    background-color: white;
}
.drawer-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

/* affix-top から affix になる境目で要素が見えなくなる現象の対策 */
.discussion-aside .affix-top {
    transform: translate3d(0, 0, 0);
}

.history-comment-pagination .pagination {
    float: right;
}
@media (max-width: 767px) {
    .history-comment-pagination .pagination + * {
        clear: right;
    }
}

/* 横に長いdiffは英単語を切ってでも折り返して表示 */
.Differences td {
    word-break: break-all;
}

.highlight {
    background-color: yellow;
}
