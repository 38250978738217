/* レイアウト */

.fp-root {
    margin: 0 -15px 0 -5px;
}
.fp-main {
    float: left;
    width: 820px;
}
.fp-aside {
    float: right;
    width: 300px;
    margin-left: 40px;
}

@media (max-width: 1199px) {
    .fp-main {
        width: 620px;
    }
    .fp-aside {
        margin-left: 30px;
    }
}
@media (max-width: 991px) {
    .fp-root {
        margin: 0;
    }
    .fp-main,
    .fp-aside {
        float: none;
        margin: 0 0 20px;
        width: auto;
    }
}
@media (max-width: 767px) {
    /*.fp-main,*/
    /*.fp-aside {*/
        /*float: none;*/
        /*margin: 20px 10px;*/
        /*width: auto;*/
    /*}*/
}
@media (max-width: 479px) {
    /*.fp-main {*/
        /*width: 100%;*/
    /*}*/
    /*.fp-aside {*/
        /*width: 100%;*/
    /*}*/
}

.fp-main-1 {
}
.fp-main-2 {
}

.fp-aside-1 {
    margin-bottom: -250px;
}
.fp-aside-2 {
    margin-top: -40px;
}

@media (max-width: 991px) {
    .fp-aside-1 {
        margin-bottom: 0;
    }
    .fp-aside-2 {
        margin-top: 0;
    }
}

/* パーツ */

.fp-sprite {
    display: inline-block;
    background: url(../img/frontpage.png) 0 0;
    /*background-repeat: no-repeat;*/
    text-indent: -9999px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    .fp-sprite {
        background: url(../img/x2/frontpage-x2.png) 0 0;
        background-size: 900px 600px;
    }
}


/* スプラッシュ */

.fp-splash {
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
    position: relative;

    & h1#site-title {
        margin: 0 auto 10px;
    }

    & .lead {
        font-size: 14px;
        vertical-align: bottom;
    }

    & .fps-mascot-01 {
        position: absolute;
        top: 50px;
        left: -10px;
    }
    & .fps-mascot-02 {
        position: absolute;
        top: 70px;
        right: -10px;
        z-index: 2;
    }

    @media (max-width: 1199px) {
        & .fps-mascot-01 {
            left: -70px;
        }
        & .fps-mascot-02 {
            right: -30px;
        }
    }
    @media (max-width: 991px) {
        & .fps-mascot-01 {
            left: -30px;
        }
        & .fps-mascot-02 {
            right: -10px;
        }
    }
    @media (max-width: 767px) {
        min-height: 260px;

        & .fps-mascot-01 {
            left: 40px;
            top: 140px;
        }
        & .fps-mascot-02 {
            right: 40px;
            top: 160px;
        }
    }
    @media (max-width: 479px) {
        min-height: 320px;

        & .fps-large-logo {
            width: 280px;
            height: 123px;
            background-position: 0 -445px;
        }
        & .fps-mascot-01 {
            left: 0;
            top: 200px;
        }
        & .fps-mascot-02 {
            right: 0;
            top: 220px;
        }
    }
}

/* 汎用 */

.fp-heading-1 {
    background: rgb(255,255,255);
    background: linear-gradient(#ffffff 0%,#dfdfdf 100%);
    border: 1px solid #d6d6d6;
    /*noinspection CssOverwrittenProperties*/
    border-left: 14px solid #0293cc;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 135%;
    color: #555;
    padding: 10px 15px 10px 5px;
    margin-bottom:20px;

    &:before {
        display: inline-block;
        content: "";
        margin: -10px 10px -10px 0;
        width: 48px;
        height: 30px;
        background: url(../img/frontpage.png) no-repeat -460px -20px;
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        &:before {
            background: url(../img/x2/frontpage-x2.png) no-repeat -460px -20px;
            background-size: 900px 600px;
        }
    }
}

.fp-heading-2 {
    background-color: #00b2db;
    border-radius: 5px;
    color: #fff;
    font-size: 100%;
    font-weight: bold;
    padding: 8px 10px;
    line-height: 19px;

    &:after {
        display: inline-block;
        float: right;
        content: "";
        width: 23px;
        height: 33px;
        margin: -8px 0 0 0;
        background: url(../img/frontpage.png) no-repeat -508px -20px;
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        &:after {
            background: url(../img/x2/frontpage-x2.png) no-repeat -1016px -40px;
            background-size: 900px 600px;
        }
    }
}

/* 新規作成 */

.fp-new-guidance {
    margin: 0 0 20px;
    text-align: center;

    & .btn {
        width: 300px;
        font-weight: bold;
        color: #ffffff;
        padding: 30px 0;
        border-radius: 8px;
        background-image: linear-gradient(#fc7869, #fd6959 60%, #ee5241);
        background-repeat: no-repeat;
        border-bottom: 1px solid #ee5241;
    }
    & .btn:hover,
    & .btn:focus,
    & .btn:active {
        color: #ffff00;
    }
    @media (max-width: 991px) {
        & .btn {
            width: 100%;
            padding: 18px 0;
        }
    }
}

/* サインアップ */

.fp-signup-guide {
    position: relative;
    background: #bbc441;
    border-radius: 5px;
    color: #fff;
    padding: 10px 10px 30px;
    text-align: center;
    max-width: 300px;
    min-height: 225px;
    margin: 0 auto 20px;
}

@media (max-width: 991px) {
    .fp-aside-1 {
        margin: 0 50px;
    }
    .fp-aside-1 .ad-block {
        text-align: center;
    }
    .fp-aside-1:after {
        clear: both;
        content: " ";
        display: table;
    }
}
@media (max-width: 767px) {
    .fp-aside-1 {
        margin: 0;
    }
    .fp-aside-1 .search-form {
        max-width: 300px;
        margin: 0 auto;
    }
    .fp-aside-1 .fp-signup-guide {
        float: none;
        margin: 20px auto;
    }
    .fp-aside-1 .ad-block {
        float: none;
        margin: 20px auto;
        width: auto;
        text-align: center;
    }
    .fp-aside-1:after {
        clear: none;
        content: "";
        display: table;
    }
}
.fp-signup-guide h3 {
    color: #fff;
    font-size: 20px;
    margin: 2px 0 10px;
}
.fp-signup-guide .fps-signup-infograph {
    margin: 10px 0;
}
.btn-fp-orange {
    background: rgb(255,173,42);
    background: -webkit-linear-gradient(#ffad2a 0%,#f25a00 100%);
    background: linear-gradient(#ffad2a 0%,#f25a00 100%);
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    font-weight:bold;
    margin-bottom:10px;
    width: 70%;
}
.btn-fp-blue {
    background: rgb(94,156,230);
    background: -webkit-linear-gradient(#5e9ce6 0%,#3b5998 100%);
    background: linear-gradient(#5e9ce6 0%,#3b5998 100%);
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    font-weight:bold;
    margin-bottom:10px;
    width: 70%;
}

.fp-signup-guide a {
    color: #fff;
}
.fp-signup-guide a:hover,
.fp-signup-guide a:focus,
.fp-signup-guide a:active {
    color:#FFD655;
}

.fp-signup-guide-login {
    margin-top: -16px;
    font-size: 12px;
}

.fp-signup-guide:after {
    content: "";
    position: absolute;
    right: 8px;
    bottom: 8px;
    text-indent: -9999px;
    width: 40px;
    height: 32px;
    background: url(../img/frontpage.png) no-repeat -467px -170px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    .fp-signup-guide:after {
        background: url(../img/x2/frontpage-x2.png) no-repeat -467px -170px;
        background-size: 900px 600px;
    }
}

/* サイト紹介 */

.fp-user-guide {
    padding: 0;

    & >li {
        display: block;
        list-style: none;
    }
    & >li:before {
        content: "";
        float: left;
        background: url(../img/frontpage.png) 0 0 no-repeat;
        text-indent: -9999px;
        width: 52px;
        height: 52px;
        margin-right: 20px;
    }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        & >li:before {
            background: url(../img/x2/frontpage-x2.png) no-repeat;
            background-size: 900px 600px;
        }
    }
    & .fp-user-guide-1:before {
        background-position: 0 -356px;
    }
    & .fp-user-guide-2:before {
        background-position: -52px -356px;
    }
    & .fp-user-guide-3:before {
        background-position: -104px -356px;
    }
    & .fp-user-guide-4:before {
        background-position: -156px -356px;
    }
    & .fp-user-guide-5:before {
        background-position: -208px -356px;
    }
    & li h3 {
        padding-left: 72px;
        font-size: 18px;
    }
    & li h3:before {
        content: "";
        display: inline-block;
        text-indent: -9999px;
        width: 16px;
        height: 16px;
        background: url(../img/frontpage.png) no-repeat 0 -408px;
        margin-right: 5px;
    }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        & li h3:before {
            background: url(../img/x2/frontpage-x2.png) no-repeat 0 -408px;
            background-size: 900px 600px;
        }
    }

    & li h3 strong {
        color: #f15a24;
    }
    & li p {
        padding-left: 72px;
    }
}

/* ホットなトピック */

.fp-hot-topics .fp-topic-item {
    float: left;
    width: 390px;
    margin: 0 15px 15px 0;
    height: 100px;
    overflow-y: hidden;

    & .thumbnail {
        position: relative;
        width: 96px;
        height: 96px;
        margin-bottom: 0;
        padding: 0;
        border: none;
        border-radius: 6px;
        overflow: hidden;
    }
    & .thumbnail .ranking {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0 0.5em;
        border-top-left-radius: 4px;
        background-color: rgba(0, 0, 0, 0.3);
        font-size: 12px;
        color: white;
    }
    & .fp-topic-item-text {
        padding-left: 108px;
    }
    & .fp-topic-item-topic {
        margin: 5px 0 12px;
        font-size: 16px;
        font-weight: bold;
        line-height: 120%;
        max-height: 55px;
    }
    & .fp-topic-item-topic a {
        color: #555;
    }

    & .fp-topic-item-info {
        font-size: 12px;
        overflow-y: visible;
    }
    & .fp-topic-item-group {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & .fp-topic-item-group a {
        color: #555;
    }
    & .fp-topic-item-labels {
    }
    @media (max-width: 1199px) {
        width: 290px;
    }
    @media (max-width: 979px) {
        width: 340px;
    }
    @media (max-width: 767px) {
        float: none;
        width: auto;
        margin: 0 auto 15px;
    }
}
@media (max-width: 767px) {
    .fp-hot-topics {
        margin: 0;
    }
}

/* おしらせ */

.fp-announcement-header {
    float: left;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
}
.fp-announcement-header .label {
    display: inline-block;
    width: 70px;
    margin-left: 4px;
}
.fp-announcement-message {
    font-size: 14px;
    padding-left: 170px;
}
@media (max-width: 767px) {
    .fp-announcement-header {
        float: none;
    }
    .fp-announcement-message {
        padding-left: 0;
    }
}
.fp-announcement-message > p:first-child,
.fp-announcement-detail > p:first-child {
    margin-top: 0;
}
.fp-announcement-detail > p:first-child {
    margin-top: -4px;
}
.fp-announcement-message > p:last-child,
.fp-announcement-detail > p:last-child {
    margin-bottom: 0;
}

.fp-announcement-detail {
    font-size: 12px;
    margin: 0 0 0 4px;
    color: #888;
    padding-left: 12px;
    border-left: 1px solid #ccc;
}

/* フォーラム */

.fp-hot-forums .list-view-item {
    margin: 10px 0 0;
    padding: 0;
    border: none;

    & .thumbnail {
        position: relative;
        width: 64px;
        height: 64px;
        margin-bottom: 0;
        padding: 0;
        border: none;
        border-radius: 6px;
        overflow: hidden;
    }
    & .thumbnail .ranking {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0 0.5em;
        border-top-left-radius: 4px;
        background-color: rgba(0, 0, 0, 0.3);
        font-size: 11px;
        color: white;
    }
    & .fp-forum-item-text {
        padding-left: 74px;
    }
    & h3 {
        margin: 4px 0;
        font-size: 14px;
        font-weight: bold;
        line-height: 120%;
        white-space: nowrap;
        overflow-x: hidden;
        overflow-y: hidden;
        text-overflow: ellipsis;
    }
    & h3 a {
        color: #555;
    }
    & p {
        font-size: 12px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 991px) {
    .fp-hot-forums, .fp-official-twitter {
        float: left;
        width: 350px;
    }
    .fp-hot-forums {
        margin: 0 20px 0 0;
    }
}
@media (max-width: 767px) {
    .fp-hot-forums, .fp-official-twitter {
        float: none;
        width: auto;
    }
    .fp-hot-forums {
        margin: 0;
    }
}

/* コメント */
.fp-recent-comments {
    & .comment-forum-symbol {
        width: 64px;
        height: 64px;
        margin: 0 10px 0 0;
    }

    & .fp-comment-item {
        margin: 0 0 20px;

        & .comment-header {
            font-size: 90%;

            & .posted-user {
                margin-right: 0.5em;
            }
            & .comment-timestamp {
                display: inline-block;
                margin-right: 0.5em;
            }
            & .comment-timestamp a {
                color: #333;
            }
            & .comment-timestamp a:active,
            & .comment-timestamp a:focus,
            & .comment-timestamp a:hover {
                color: #44aadd;
            }
            & .comment-parent-link {
                color: #cccccc;
            }
            & .comment-parent-link:hover,
            & .comment-parent-link:focus,
            & .comment-parent-link:active {
                color: #2183b4;
            }
        }
    }

    & .comment-header-body-container {
        margin-left: 74px;

        & .comment-body {
            margin: 4px 0 2px;
        }
        & .comment-body .content {
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
        }
    }
}

/* 新着グループ */
.fp-recent-forums {

    & .fp-forum-item {
        float: left;
        width: 390px;
        margin: 0 15px 15px 0;
        height: 72px;
    }

    @media (max-width: 1199px) {
        & .fp-forum-item {
            width: 290px;
        }
    }

    @media (max-width: 979px) {
        & .fp-forum-item {
            width: 340px;
        }
    }

    @media (max-width: 767px) {
        margin: 0;

        & .fp-forum-item {
            float: none;
            width: auto;
            margin: 0 auto 15px;
        }
    }

    & .fp-forum-item {
        & .thumbnail {
            width: 64px;
            height: 64px;
            margin: 0 10px 0 0;
            padding: 0;
            border: none;
            border-radius: 4px;
            overflow: hidden;
        }

        & .fp-forum-item-text {
            padding-left: 74px;
        }

        & .topic-detail-aside {
            float: right;
            text-align: right;
            margin-bottom: 0;
            color: #666;
        }

        & .topic-detail-aside > small {
            display: block;
            white-space: nowrap;
        }

        & h3 {
            margin: 4px 0;
            font-size: 14px;
            font-weight: bold;
            line-height: 120%;
            white-space: nowrap;
            overflow-x: hidden;
            overflow-y: hidden;
            text-overflow: ellipsis;
        }

        & h3 a {
            color: #555;
        }

        & p {
            font-size: 12px;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
    }
}

/* フッタ */

.frontpage .wrap {
    background: url(../img/frontpage-footer-bg.png) no-repeat center bottom;
    padding-bottom: 120px;
    border-bottom: 140px solid #e8e8e8;

    @media (max-width: 719px) {
        background: url(../img/frontpage-footer-bg-s.png) no-repeat center bottom;
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background: url(../img/x2/frontpage-footer-bg-x2.png) no-repeat center bottom;
        background-size: 50%;
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 719px), (min-resolution: 2dppx) and (max-width: 719px) {
        background: url(../img/x2/frontpage-footer-bg-s-x2.png) no-repeat center bottom;
        background-size: 50%;
    }
}

