.targeted-confirm-modal {
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    max-width: 276px;
    display: none;
    z-index: 100;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    border-top: 1px solid rgba(221, 221, 221, .5);

    &.open {
        display: block;
    }

    &.top {
        margin-top: -10px;
    }

    &.bottom {
        margin-top: 10px;
    }

    & .targeted-modal-body {
        padding: 8px 14px;
    }

    & .targeted-modal-footer {
        text-align: right;
        padding: 8px 14px;

        & button:not(:last-child) {
            margin-right: 8px;
        }
    }
}

.embed-theme-dark .targeted-confirm-modal {
    background-color: #444444;
    border-color: #696969;
}
