.ad-block {
}

.ad-block-between-topic-comments {
    border-top: 1px solid #ddd;
    margin-bottom: -1px;
}

.ad-block-between-topic-comments-0 {
}
