.scroll-to-top-bottom {
    position: fixed;
    border-radius: 4px;
    border: none;
    background: #000;
    opacity: 0.2;
    color: #ffffff;
    right: 20px;
    width: 42px;
    height: 40px;
    outline: none;
    z-index: 1039;

    &:hover {
        opacity: 0.5;
    }
    &:active {
        opacity: 0.2;
    }

    @media (max-width: 767px) {
        right: 10px;
    }
}

.scroll-to-top-bottom-top {
    bottom: 225px;
}
.scroll-to-top-bottom-bottom {
    bottom: 175px;
}

