.discussion .navbar {
    background: #ffffff;
    border-color: #cccccc;
    color: #666666;
}
.discussion .brand-logo {
    background: url("../img/logo-inverse.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    .discussion .brand-logo {
        background: url("../img/x2/logo-inverse-x2.png");
        background-size: 100%;
    }
}

.discussion .navbar-default .navbar-nab-no-hamburger > li > a,
.discussion .navbar-default .navbar-nab-no-hamburger > li > a:focus {
    color: #666666;
    background: #ffffff;
}
.discussion .navbar-default .navbar-nab-no-hamburger > li > a:hover,
.discussion .navbar-default .navbar-nab-no-hamburger > .open > a,
.discussion .navbar-default .navbar-nab-no-hamburger > .open > a:hover,
.discussion .navbar-default .navbar-nab-no-hamburger > .open > a:focus {
    color: #666666;
    background: #eeeeee;
}


.discussion .navbar-default .navbar-nab-no-hamburger > .nav-inbox > a {
    color: #666666;
}
.discussion .navbar-default .navbar-nab-no-hamburger > .nav-inbox > a:hover,
.discussion .navbar-default .navbar-nab-no-hamburger > .nav-inbox > a:focus {
    background: none;
}

a.rss-button {
    display: inline-block;
    height: 20px;
    width: 60px;
    border-radius: 3px;
    padding: 3px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    background-color: orange;
    color: white;
    text-decoration: none;
}
a.rss-button:hover {
    background-color: darkorange;
    color: white;
}
a.rss-button i {
    vertical-align: middle;
}
a.rss-button span {
    line-height: 11px;
    font-size: 11px;
    font-weight: bold;
    vertical-align: middle;
}
