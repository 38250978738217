.referenced-comments-area {
}

.referenced-comments-area .comments-head {
    text-align: left;
}
.referenced-comments-area .close {
    float: none;
}

.referenced-comments-area .expanded-comment-ref {
    border-left: 4px solid #ccc;
    padding: 0 0 0 10px;
    margin: 0 0 6px 4px;
}

.referenced-comments-area .expanded-comment-ref:last-child {
    /*border-bottom: 1px solid #ddd;*/
}

.referenced-comments-area .expanded-comment-ref .comment-header-body-container,
.referenced-comments-area .expanded-comment-ref .comment-header-body-deleted {
    padding-left: 42px;
}

.referenced-comments-area .expanded-comment-ref .comment-icon {
    width: 34px;
    height: 34px;
}
.referenced-comments-area .expanded-comment-ref .comment-icon img {
    height: 100%;
}

/*
@media (max-width: 479px) {
    .referenced-comments-area .comment-icon-container {
        display: none;
    }

    .referenced-comments-area .comment-header-body-container,
    .referenced-comments-area .comment-header-body-deleted {
        padding-left: 0;
    }
}
*/
