.simple-emoji-palette {
    position: absolute;
    background-color: #fff;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: none;
    z-index: 100;
    max-width: 225px;

    &.open {
        display: block;
    }

    & ul {
        list-style: none;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 0;

        & li {
            cursor: pointer;
            margin: 0 6px;
        }
    }
}

// パレット上の絵文字のザイズ
.simple-emoji-palette .twa {
    height: 1.5em;
    width: 1.5em;
    margin: 0.1em 0.3em;
    vertical-align: -0.2em;
    background-size: 1.5em 1.5em;
}
