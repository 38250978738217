
.wrap {
    margin: 0 auto 0;
    padding: 0 0 20px;
}

.brand-logo {
    display: block;
    width: 160px;
    height: 30px;
    margin: -5px 0;
    background: url("../img/logo.png");
    text-indent: -9999px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    .brand-logo {
        background: url("../img/x2/logo-x2.png");
        background-size: 100%;
    }
}

.nowrap {
    white-space: nowrap;
}

.btn-link .caret {
    border-top-color: #44aadd;
}

.btn-link.disabled, .btn-link[disabled], fieldset[disabled] .btn-link {
    color: #888;
}

.dropdown-menu > .disabled{
    cursor: not-allowed;
}
/* The real magic ;) */
.dropdown-menu > .disabled > a{
    pointer-events: none;
}

.owner-icon img, .comment-icon img, .user-icon img,
.owner-icon a > img, .comment-icon a > img, .user-icon a > img {
    height: 100%;
}

.wrap .navbar {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
}
.navbar-nab-no-hamburger {
    /*noinspection CssFloatPxLength*/
    margin: 7.5px -15px;
}
.navbar-nab-no-hamburger > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
}
.navbar-nab-no-hamburger {
    float: left;
    margin: 0;
}
.navbar-nab-no-hamburger > li {
    float: left;
}
.navbar-nab-no-hamburger > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
}
.navbar-nab-no-hamburger.navbar-right:last-child {
    margin-right: -15px;
}
.navbar-nab-no-hamburger > li > .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.navbar-default .navbar-nab-no-hamburger > li > a {
    color: #ffffff;
}
.navbar-default .navbar-nab-no-hamburger > li > a:hover,
.navbar-default .navbar-nab-no-hamburger > li > a:focus {
    color: #ffffff;
    background-color: #2592c9;
}
.navbar-default .navbar-nab-no-hamburger > .active > a,
.navbar-default .navbar-nab-no-hamburger > .active > a:hover,
.navbar-default .navbar-nab-no-hamburger > .active > a:focus {
    color: #ffffff;
    background-color: #2592c9;
}
.navbar-default .navbar-nab-no-hamburger > .disabled > a,
.navbar-default .navbar-nab-no-hamburger > .disabled > a:hover,
.navbar-default .navbar-nab-no-hamburger > .disabled > a:focus {
    color: #dddddd;
    background-color: transparent;
}

.navbar-default .navbar-nab-no-hamburger > .open > a,
.navbar-default .navbar-nab-no-hamburger > .open > a:hover,
.navbar-default .navbar-nab-no-hamburger > .open > a:focus {
    background-color: #2592c9;
    color: #ffffff;
}

@media (min-width: 768px) {
    .search-query-input {
        max-width: 190px; /* メイリオ対策 */
    }
}

.navbar-default .navbar-nab-no-hamburger > .nav-inbox {
    position: relative;
}
.navbar-default .navbar-nab-no-hamburger > .nav-inbox > a {
    display: block;
    padding: 17px 10px 8px;
    height: 50px;
    color: #ffffff;
}
.navbar-default .navbar-nab-no-hamburger > .nav-inbox > a:hover,
.navbar-default .navbar-nab-no-hamburger > .nav-inbox > a:focus {
    background: none;
}
.navbar-default .navbar-nab-no-hamburger > .nav-inbox .icon {
    font-size: 19px;
    opacity: 0.8;
}
.navbar-default .navbar-nab-no-hamburger > .nav-inbox > a:hover .icon,
.navbar-default .navbar-nab-no-hamburger > .nav-inbox > a:focus .icon {
    opacity: 1;
}
.navbar-default .navbar-nab-no-hamburger > .nav-inbox .badge {
    position: absolute;
    right: -5px;
    top: 10px;
    font-size: 8px;
    background-color: #ff0000;
    color: #ffffff;
    z-index: 2;
}

.navbar .user-icon {
    display: inline-block;
    width: 38px;
    height: 38px;
    margin: -15px 4px -15px 0;
    padding: 0;
    border: none;
    background: #ffffff;
    border-radius: 2px;
    overflow: hidden;
}
.navbar .user-nickname {
    display: inline-block;
    white-space: nowrap;
    max-width: 12em;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.navbar .dropdown-header {
    color: #999999;
}

.discussion-above {
}
.discussion-above .ad-block {
    display: none;
}
.discussion-main {
    float: left;
    width: 820px;
}
.discussion-aside {
    float: right;
    width: 300px;
}
.discussion-aside .ad-block {
    float: left;
    width: 100%;
    text-align: center;
}
@media (max-width: 1199px) {
    .discussion-main {
        width: 620px;
    }
}
@media (max-width: 991px) {
    .discussion-main {
        float: none;
        width: 100%;
        margin-bottom: 60px;
    }
    .discussion-aside {
        float: none;
        width: 100%;
    }
    .discussion-aside .ad-block {
        width: 50%;
    }
}
@media (max-width: 767px) {
    .discussion-aside .ad-block {
        width: 100%;
    }
}
@media (max-width: 479px) {
    .wrap > .container {
        padding-left: 10px;
        padding-right: 10px;
    }
    .wrap > .container .row {
        margin-left: -10px;
        margin-right: -10px;
    }
    .wrap > .container .col-xs-1,
    .wrap > .container .col-sm-1,
    .wrap > .container .col-md-1,
    .wrap > .container .col-lg-1,
    .wrap > .container .col-xs-2,
    .wrap > .container .col-sm-2,
    .wrap > .container .col-md-2,
    .wrap > .container .col-lg-2,
    .wrap > .container .col-xs-3,
    .wrap > .container .col-sm-3,
    .wrap > .container .col-md-3,
    .wrap > .container .col-lg-3,
    .wrap > .container .col-xs-4,
    .wrap > .container .col-sm-4,
    .wrap > .container .col-md-4,
    .wrap > .container .col-lg-4,
    .wrap > .container .col-xs-5,
    .wrap > .container .col-sm-5,
    .wrap > .container .col-md-5,
    .wrap > .container .col-lg-5,
    .wrap > .container .col-xs-6,
    .wrap > .container .col-sm-6,
    .wrap > .container .col-md-6,
    .wrap > .container .col-lg-6,
    .wrap > .container .col-xs-7,
    .wrap > .container .col-sm-7,
    .wrap > .container .col-md-7,
    .wrap > .container .col-lg-7,
    .wrap > .container .col-xs-8,
    .wrap > .container .col-sm-8,
    .wrap > .container .col-md-8,
    .wrap > .container .col-lg-8,
    .wrap > .container .col-xs-9,
    .wrap > .container .col-sm-9,
    .wrap > .container .col-md-9,
    .wrap > .container .col-lg-9,
    .wrap > .container .col-xs-10,
    .wrap > .container .col-sm-10,
    .wrap > .container .col-md-10,
    .wrap > .container .col-lg-10,
    .wrap > .container .col-xs-11,
    .wrap > .container .col-sm-11,
    .wrap > .container .col-md-11,
    .wrap > .container .col-lg-11,
    .wrap > .container .col-xs-12,
    .wrap > .container .col-sm-12,
    .wrap > .container .col-md-12,
    .wrap > .container .col-lg-12 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .discussion-aside {
        width: auto;
    }
    .discussion-aside .ad-block {
        margin: 0 auto;
        /*min-width: 320px;*/
    }
    /*
    .discussion-above {
        margin: -20px 0 10px;
        padding: 5px 15px;
        background: #333;
    }
    .discussion-above .ad-block {
        display: block;
        margin: 0 -15px;
        text-align: center;
        overflow-x: hidden;
    }
    .discussion-above img {
        width: 100%;
    }
    */
}

.discussion-aside .info-container {
    margin-top: 10px;
}
.discussion-aside .info {
    width: 300px;
}
.discussion-aside .info.affix {
    position: fixed;
    top: 10px;
}
.discussion-aside .info.affix-bottom {
    position: absolute;
}
@media (max-width: 991px) {
    .discussion-aside .info {
        width: 100%;
    }
    .discussion-aside .info.affix,
    .discussion-aside .info.affix-bottom {
        position: static;
    }
}

.discussion-aside .aside-group,
.drawer-discussion-aside .aside-group {
    border: 3px solid #eee;
    padding: 6px 10px;
    margin-bottom: 20px;
    border-radius: 6px;
}
.discussion-aside .aside-group .aside-group-title:first-child,
.drawer-discussion-aside .aside-group .aside-group-title:first-child {
    margin-top: 6px;
    font-size: 18px;
    font-weight: bold;
}
.discussion-aside .aside-group .aside-group-title a,
.drawer-discussion-aside .aside-group .aside-group-title a {
    color: #317EAC;
}
.discussion-aside .aside-group .list-view:last-child,
.drawer-discussion-aside .aside-group .list-view:last-child {
    margin-bottom: 0;
}
.discussion-aside .aside-group:last-child,
.drawer-discussion-aside .aside-group:last-child {
    margin-bottom: 0;
}

.discussion-aside .aside-group.aside-group-share-buttons {
    margin-top: 4px;
}

.discussion-aside .aside-nav {
    margin-bottom: 20px;
}
.drawer-nav .aside-nav {
    margin: 10px 10px 0;
}

.drawer-nav .aside-group.aside-group-share-buttons {
    margin-top: 4px;
}

.drawer-nav .aside-group {
    border: 3px solid #eee;
    padding: 6px 10px;
    margin: 10px;
    border-radius: 6px;
}

.aside-group.aside-group-hidden {
    display: none;
    margin: 0;
    padding: 0;
    height: 0;
    border: none;
}

/* list */

.list-view {
    margin-bottom: 20px;
    padding-left: 0;
}
.list-view-item {
    position: relative;
    display: block;
    padding: 10px 0;
    margin-bottom: -1px;
    /*background-color: #ffffff;*/
    border-top: 1px solid #dddddd;
    /*border-bottom: 1px solid #dddddd;*/
}
.list-view-item-heading {
    margin-top: 0;
    margin-bottom: 5px;
}
.list-view-item:first-child {
}
.list-view-item:last-child {
    margin-bottom: 0;
}


/* comment form */
.comment-refresh-container {
    float: right;
}
.comment-refresh-container button .text {
    display: none;
}
@media (max-width: 767px) {
    .comment-refresh-container {
        float: none;
        margin-bottom: 20px;
    }
    .comment-refresh-container button {
        width: 100%;
    }
    .comment-refresh-container button .text {
        display: inline;
    }
}

/* symbol image */

.symbol-image-sm {
    position: relative;
    width: 64px;
    height: 64px;
}
.symbol-image-sm .thumbnail {
    position: absolute;
    border-radius: 3px;
    padding: 0;
    margin-bottom: 0;
    border: none;
    overflow: hidden;
    max-width: 100%;
}
.symbol-image-sm .forum-symbol {
    width: 50px;
    height: 50px;
}
.symbol-image-sm .comment-icon {
    right: 1px;
    bottom: 1px;
    width: 34px;
    height: 34px;
    border-radius: 2px;
    padding: 0;
    box-shadow:rgba(0, 0, 0, 0.2) 1px 2px 5px 0;
}


/* misc */

.page-header {
    margin-top: 0;
}

/* modal */

.app-modal .modal-header {
    background-color: #44aadd;
    margin: -1px -1px 0 -1px;
    padding: 10px 15px 4px;
    border-radius: 6px 6px 0 0;
    border: 1px solid #999999;
    /*noinspection CssOverwrittenProperties*/
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.app-modal .modal-header .close,
.app-modal .modal-header .modal-title {
    color: #ffffff;
    font-size: 14px;
}
.app-modal .modal-header .close {
    opacity: 0.8;
}
.app-modal .modal-header .close:hover {
    opacity: 1.0;
}
.app-modal .modal-footer {
    padding: 10px 20px;
    border-top: none;
}

/* slider pager */

.slider-paging .pagination {
    display: table-row;
    width: 100%;
}
.slider-paging .pagination li {
    display: table-cell;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0 0 0 5px;
    border-collapse: separate;
}
.slider-paging .pagination li:first-child {
    padding-left: 0;
}
.slider-paging .pagination li.slider-pager {
    width: 98%;
    padding: 0 20px;
}
.slider-paging .pagination li.slider-pager .slider {
    width: 100%;
}
.slider-paging .pagination input {
    display: none;
}

.embedded-content {
    max-width: 500px;
}

.hashed-track-info {
    display: inline-block;
    margin-right: 6px;
    color: #bbbbbb;
}


.ad-hoc-hint {
    color: #555555;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.ad-hoc-hint .ad-hoc-hint-icon {
    padding: 3px 4px 3px 0;
}
.ad-hoc-hint .ad-hoc-hint-icon-offset {
    flex: auto;
    vertical-align: top;
}
.ad-hoc-hint .close {
    margin-top: -1px;
}
.ad-hoc-hint-below-navbar {
    margin: -20px 0 20px;
    padding: 6px 0;
    border-bottom: 1px solid transparent;
}
.ad-hoc-hint-below-navbar .container {
    padding: 0 20px;
}
.ad-hoc-hint-above-comment-form {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.ad-hoc-hint-management-console {
    margin: -20px -15px 20px -25px;
    padding: 6px 20px;
    border-bottom: 1px solid transparent;
}
.forum-management-main-full .ad-hoc-hint-management-console {
    margin-left: -15px;
}
@media (max-width: 767px) {
    .ad-hoc-hint-management-console {
        margin-left: -15px;
    }
}
.ad-hoc-hint-color-info {
    background-color: #e6f7ff;
    border-color: #cdf2fa;
}
.ad-hoc-hint-color-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #468847;
}
.ad-hoc-hint-color-warning {
    background-color: #ffffdd;
    border-color: #ccccaa;
    color: #c09853;
}
.ad-hoc-hint-color-danger {
    background-color: #f2dede;
    border-color: #eed3d7;
    color: #b94a48;
}

textarea.md-code-editor {
    font-family: Menlo,Monaco,Consolas,"Courier New", 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ ゴシック', 'MS Gothic', monospace;
}
textarea.md-code-editor.auto-size {
    height: 6em;
    min-height: 6em;
    max-height: 20em;
}

.ekko-lightbox .modal-dialog {
    margin: 30px auto;
}
@media all and (-ms-high-contrast:none){
    .ekko-lightbox {
        display: block !important;
    }
}
/* BUG https://github.com/ashleydw/lightbox/issues/277 */

.abuse-report-body {
	margin-left: 52px;
}

.abuse-thumbnail {
	background-color: #fff;
	border-radius: 5px;
	width: 42px;
	height: 42px;
	float: left;
	margin-right: 10px;
}

.abuse-thumbnail > img {
	width: 100%;
    overflow: auto; /* リンク切れのときのaltがはみ出さないようにする */
    white-space: pre;

}

.form-user-nickname {
    width: 80%;
}

.form-thumbnail {
    float: left;
    width: 40px;
    margin-right: 10px;
}

.form-thumbnail > img {
    width: 100%;
}

.with-label {
    margin-top: 24px;
}

.with-thumbnail {
    margin-left: -48px;
}
