.comment.emoji-reaction-enabled {
    & .content .comment-reactions-container {
        display: flex;
        align-items: center;
        padding-left: 50px;
        margin-bottom: 8px;

        & .btn-group {
            display: block;
        }

        & .reaction-buttons button.reaction-button {
            background-color: #efefef;
            border: 1px solid #efefef;
            border-radius: 12px;
            margin-right: 8px;
            margin-bottom: 4px;

            &[disabled] {
                opacity: 0.5;
            }
        }
        & .reaction-buttons button.reaction-button:hover {
            background-color: #fff;
            border: solid 1px;
        }
        & .reaction-buttons button.reaction-button.self-reaction {
            background-color: rgba(29, 155, 209, 0.1);
            border: solid 1px rgb(29, 155, 209);
        }

        & .reaction-buttons button .twa {
            font-size: 16px;
            vertical-align: text-bottom;
        }

        & .reaction-buttons button .reaction-count {
            font-size: 12px;
        }
    }

    /* menu */
    & .content .comment-action-menu {
        & .dropdown-menu {
            left: auto;
            right: 0;
        }
    }

    /* user-list */
    & .reaction-user-list-container .reaction-user-list {
        margin-top: 16px;

        & p {
            font-size: 8px;
        }

        & ul.comment-reaction-groups {
            list-style: none;
            padding-left: 0;

            & .emoji-image {
                margin-right: 4px;
            }

            & .each-emoji-reaction {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
            }

            & .each-emoji-reaction ul.user-list {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                background-color: #f5f5f5;
                padding: 4px;
                border-radius: 4px;
            }

            & .each-emoji-reaction ul.user-list li {
                margin-right: 1em;
                font-size: 8px;
            }

            & .each-emoji-reaction ul.user-list li img {
                height: 12px;
                margin-right: 4px;
                padding: 0;
                border: none;
            }
        }
    }
}

.child-comments .comment.emoji-reaction-enabled {
    & .content .comment-reactions-container {
        padding-left: 42px;
    }
}

.comment .content .comment-reactions-container {
    // emoji リアクションが描画されたときにカクカクしないように事前に高さを確保しておく用
    // .emoji-reaction-enabled は jquery.emoji-reaction.js によって動的に付与されるため
    // その前に高さを確保しておくためには emoji-reaction-enabled なしの状態で height を指定しておく必要がある
    &.prepareHeight {
        height: 28px;
    }
}
