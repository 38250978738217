/* Page 1*/
.fps-point01 {
    width: 16px;
    height: 16px;
    background-position: 0 -408px;
}
.fps-large-logo-v {
    width: 280px;
    height: 123px;
    background-position: 0 -445px;
}
.fps-large-logo {
    width: 450px;
    height: 70px;
    background-position: 0 0;
}
.fps-inline-logo {
    width: 120px;
    height: 20px;
    background-position: -460px 0;
}
.fps-heading-mascot-1 {
    width: 48px;
    height: 30px;
    background-position: -460px -20px;
}
.fps-heading-mascot-2 {
    width: 23px;
    height: 33px;
    background-position: -508px -20px;
}
.fps-mascot-01 {
    width: 171px;
    height: 112px;
    background-position: -28px -90px;
}
.fps-mascot-02 {
    width: 142px;
    height: 86px;
    background-position: -206px -90px;
}
.fps-keypoint-1 {
    width: 144px;
    height: 94px;
    background-position: -32px -245px;
}
.fps-keypoint-2 {
    width: 144px;
    height: 94px;
    background-position: -176px -245px;
}
.fps-keypoint-3 {
    width: 144px;
    height: 94px;
    background-position: -320px -245px;
}
.fps-keypoint-4 {
    width: 144px;
    height: 94px;
    background-position: -464px -245px;
}
.fps-abstraction-icon-1 {
    width: 52px;
    height: 52px;
    background-position: 0 -356px;
}
.fps-abstraction-icon-2 {
    width: 52px;
    height: 52px;
    background-position: -52px -356px;
}
.fps-abstraction-icon-3 {
    width: 52px;
    height: 52px;
    background-position: -104px -356px;
}
.fps-abstraction-icon-4 {
    width: 52px;
    height: 52px;
    background-position: -156px -356px;
}
.fps-abstraction-icon-5 {
    width: 52px;
    height: 52px;
    background-position: -208px -356px;
}
.fps-signin-infograph {
    width: 268px;
    height: 44px;
    background-position: -628px -31px;
}
.fps-mascot-03 {
    width: 40px;
    height: 32px;
    background-position: -467px -170px;
}

